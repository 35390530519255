import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const UserMenu = ({ anchorEl, handleClose,onLogout, user }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '250px',
                },
            }}
        >
            <MenuItem>
                <Typography variant="subtitle1" style={{ pointerEvents: 'none', color: 'black' }}>
                    {user.nome} {user.cognome}
                </Typography>
            </MenuItem>
            <MenuItem>
                <Typography variant="subtitle2" style={{ pointerEvents: 'none', color: 'black' }}>
                    Livello: {user.livello}
                </Typography>
            </MenuItem>
            <MenuItem onClick={onLogout}>
                <LogoutIcon />
                <Typography variant="inherit" style={{ marginLeft: '10px' }}>
                    Logout
                </Typography>
            </MenuItem>
        </Menu>
    );
};

export default UserMenu;
