import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Chip, CircularProgress, Box,Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Attivita from './Attivita'; // Assicurati che il percorso sia corretto

const Progetti = ({ idScheda }) => {
    const [progetti, setProgetti] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');

        fetch(`https://www.paoloniinformatica.it/papi/projects/getProgetti/${idScheda}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setProgetti(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Errore nel recupero dei progetti:', error);
                setLoading(false);
            });
    }, [idScheda]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Paper elevation={3} style={{ padding: '10px', backgroundColor: '#ffffff' }}>
            {progetti.length > 0 ? (
                progetti.map((progetto, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Box display="flex" alignItems="center">
                                <Chip label={progetto.siglaprogetto} color="primary" style={{ marginRight: '10px' }} />
                                <Typography variant="body1">
                                    {progetto.descrizioneprogetto}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* Passa l'idProgetto al componente Attivita */}
                            <Attivita idProgetto={progetto.idprogetto} />
                        </AccordionDetails>
                    </Accordion>
                ))
            ) : (
                <Typography variant="body2" color="textSecondary">
                    Nessun progetto associato.
                </Typography>
            )}
        </Paper>
    );
};

export default Progetti;
