import React, { useState, useEffect } from 'react';
import {
    TextField, Button, Grid, Paper, Typography, Slider, Select, MenuItem, InputLabel, FormControl, Snackbar, Alert
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

const AttivitaInsertEdit = ({ existingData, onSave, onDelete, idProgetto }) => {
    const theme = useTheme();
    const [formValues, setFormValues] = useState({
        nomeattivita: '',
        siglaattivita: '',
        avanzamento: 0,
        attivo: true,
        idprogetto_fk: idProgetto,  // L'ID Progetto è preimpostato e non visibile
        valoreattivitaprogetto: '',
        datachiusura: '',
        idprodotto_fk: '',
        quantita_attivita: ''
    });

    const [prodotti, setProdotti] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        const fetchProdotti = async () => {
            try {
                const response = await axios.get('https://www.paoloniinformatica.it/papi/masterdata/getProducts', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                });
                setProdotti(response.data);
            } catch (error) {
                console.error('Errore nel recupero dei prodotti:', error);
            }
        };
        fetchProdotti();
    }, []);

    useEffect(() => {
        if (existingData) {
            setFormValues({
                ...existingData,
                valoreattivitaprogetto: existingData.valoreattivitaprogetto.toString().replace('.', ',')
            });
        }
    }, [existingData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSliderChange = (e, newValue) => {
        setFormValues({
            ...formValues,
            avanzamento: newValue
        });
    };

    const handleCurrencyChange = (e) => {
        const value = e.target.value.replace(/[^0-9,]/g, '');
        setFormValues({
            ...formValues,
            valoreattivitaprogetto: value
        });
    };

   
const handleCurrencyBlur = () => {
    const value = formValues.valoreattivitaprogetto.replace(',', '.');
    
    const formattedValue = parseFloat(value).toFixed(2).replace('.', ',');
    
    setFormValues({
        ...formValues,
        valoreattivitaprogetto: formattedValue
    });
};


   
const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedValues = {
        ...formValues,
        valoreattivitaprogetto: typeof formValues.valoreattivitaprogetto === 'string'
            ? parseFloat(formValues.valoreattivitaprogetto.replace(',', '.')).toFixed(2)
            : formValues.valoreattivitaprogetto.toFixed(2)
    };

    try {
        const response = await axios.post('https://www.paoloniinformatica.it/papi/projects/upsertattivita', formattedValues, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (response.status === 201) {
            console.log('Risposta dal server:', response.data);
            onSave();
        } else {
            console.error('Errore durante l\'inserimento: Stato non 201', response.status);
            throw new Error(`Errore durante l'inserimento: Stato ${response.status}`);
        }
    } catch (error) {
        console.error('Errore durante l\'inserimento:', error);
        // Logica per gestire l'errore
    }
};
    

    const handleDelete = async () => {
        if (existingData && window.confirm('Sei sicuro di voler cancellare questa attività?')) {
            try {
                const response = await axios.delete(`https://www.paoloniinformatica.it/papi/deleteattivita/${existingData.idattivita}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.status === 200) {
                    setSnackbar({ open: true, message: 'Attività cancellata con successo', severity: 'success' });
                    onDelete();
                } else {
                    setSnackbar({ open: true, message: 'Errore durante la cancellazione dell\'attività', severity: 'error' });
                }
            } catch (error) {
                console.error('Errore durante la cancellazione:', error);
                setSnackbar({ open: true, message: 'Errore durante la cancellazione dell\'attività', severity: 'error' });
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({ open: false, message: '', severity: 'success' });
    };

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ width: '100%' }}>
            <Grid item xs={12} >
                <Paper style={{ padding: theme.spacing(2), backgroundColor: '#ffffff' }}>
                   
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Nome Attività"
                            name="nomeattivita"
                            value={formValues.nomeattivita}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Sigla Attività"
                            name="siglaattivita"
                            value={formValues.siglaattivita}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            required
                        />
                        <Typography gutterBottom>Avanzamento</Typography>
                        <Slider
                            value={formValues.avanzamento}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                            required
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Quantità"
                                    name="quantita_attivita"
                                    type="number"
                                    value={formValues.quantita_attivita}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Valore (€)"
                                    name="valoreattivitaprogetto"
                                    value={formValues.valoreattivitaprogetto}
                                    onChange={handleCurrencyChange}
                                    onBlur={handleCurrencyBlur}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            </Grid>
                        </Grid>

                        <TextField
                            label="Data Chiusura"
                            name="datachiusura"
                            type="date"
                            value={formValues.datachiusura}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="idprodotto-label">Prodotto</InputLabel>
                            <Select
                                labelId="idprodotto-label"
                                id="idprodotto"
                                value={formValues.idprodotto_fk}
                                name="idprodotto_fk"
                                onChange={handleInputChange}
                            >
                                <MenuItem value="">
                                    <em>--seleziona prodotto--</em>
                                </MenuItem>
                                {prodotti.map((prodotto) => (
                                    <MenuItem key={prodotto.idprodotto} value={prodotto.idprodotto}>
                                        {prodotto.nomeprodotto}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Grid container spacing={2} justifyContent="center" style={{ marginTop: theme.spacing(2) }}>
                            <Grid item>
                                <Button variant="contained" color="primary" type="submit">
                                    {existingData ? 'Aggiorna' : 'Aggiungi'}
                                </Button>
                            </Grid>
                            {existingData && (
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleDelete}>
                                        Cancella
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </Paper>
            </Grid>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default AttivitaInsertEdit;
