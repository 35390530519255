import React from 'react';
import Layout from './Layout'; // Importa il layout globale
import SchedaCard from '../components/SchedaCard';

const Schede = () => {
    return (
        <Layout>
            <SchedaCard />
        </Layout>
    );
};

export default Schede;
