// src/components/Layout.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import theme from '../theme'; // Importa il tema personalizzato
import MenuComponent from './Menu';
import UserMenu from './UserMenu'; // Assicurati che il percorso sia corretto

const Layout = ({ children }) => {
    const nome = localStorage.getItem('nome');
    const cognome = localStorage.getItem('cognome');
    const livello = localStorage.getItem('livello');

    const [anchorEl, setAnchorEl] = useState(null); // Assicurati di definire anchorEl qui

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('nome');
        localStorage.removeItem('cognome');
        localStorage.removeItem('livello');
        window.location.href = '/';
    };

    return (
        <ThemeProvider theme={theme}> {/* Avvolgi il componente con ThemeProvider */}
            <Box sx={{ display: 'flex', height: '100vh' }}>
                <AppBar position="fixed">
                    <Toolbar>
                        <MenuComponent
                            nome={nome}
                            cognome={cognome}
                            livello={livello}
                            onLogout={handleLogout}
                        />
                        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                            Paladino 
                        </Typography>
                        {/* Aggiungi l'icona del profilo per il menu utente */}
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {/* Aggiungi UserMenu per il popup in alto a destra */}
                <UserMenu
                    anchorEl={anchorEl}  // Pass the anchorEl to UserMenu
                    handleClose={handleClose}
                    user={{ nome, cognome, livello }}
                    onLogout={handleLogout}
                />

                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />
                    {children} {/* Qui vengono renderizzati i componenti figli */}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Layout;
