// src/pages/Dashboard.js
import React from 'react';
import Layout from '../components/Layout';
import { Typography } from '@mui/material';

const Dashboard = () => {
    const nome = localStorage.getItem('nome');
    const cognome = localStorage.getItem('cognome');
    const livello = localStorage.getItem('livello');

    return (
        <Layout>
            <Typography variant="h4">Benvenuto, {nome} {cognome}</Typography>
            <Typography variant="h6">Livello: {livello}</Typography>
        </Layout>
    );
};

export default Dashboard;
