import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Box, Card, CardContent, Typography, Avatar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ShieldIcon from '@mui/icons-material/Security';  // Usa un'icona di scudo da Material-UI Icons
import theme from '../theme'; // Importa il tema dal percorso corretto

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        const response = await fetch('https://www.paoloniinformatica.it/papi/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
            const data = await response.json();
            // Salva il token e le altre informazioni nel localStorage
            localStorage.setItem('token', data.token);
            localStorage.setItem('nome', data.nome);
            localStorage.setItem('cognome', data.cognome);
            localStorage.setItem('livello', data.livello);

            // Reindirizza alla dashboard
            navigate('/dashboard');
        } else {
            alert('Login failed');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: theme.palette.background.default,
            }}
        >            
         
         <Card sx={{ width: 300, padding: 2 }}>
            <CardContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    
                >
                    <Avatar sx={{ bgcolor: theme.palette.primary.main, mb: 2 }}>
                        <ShieldIcon />
                    </Avatar>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Login Paladino
                    </Typography>
                    <form onSubmit={handleLogin} style={{ width: '100%' }}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputLabelProps={{ style: { color: theme.palette.primary.main } }}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputLabelProps={{ style: { color: theme.palette.primary.main } }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{
                                marginTop: '1rem',
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.dark,
                                },
                            }}
                        >
                            Login
                        </Button>
                    </form>
                </Box>
                </CardContent>
                </Card>
            </Box>  
        </ThemeProvider>
    );
};

export default Login;
