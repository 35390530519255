import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
    typography: {
        attivitaVariant: {
          fontSize: '10px',
          fontFamily: 'Arial',
          fontWeight: 600,
          letterSpacing: '0.0em',
          color: '#3f51b5', // Colore specifico se necessario
          textalign: 'right',
        },
        attivitaVariantData: {
            fontSize: '10px',
            fontFamily: 'Arial',
            fontWeight: 600,
            letterSpacing: '0.0em',
            color: '#000000', // Colore specifico se necessario
          },
  

      },


  palette: {
    primary: {
      main: '#357a38', // Verde scuro
    },
    secondary: {
      main: '#ff9100', // Oro
    },
    info: {
      main: '#b0b0b0', // Grigio chiaro
    },
    background: {
      default: '#e0f2f1', // Colore di sfondo leggero (opzionale)
      paper:'#ffffe0',
      white:'#ffffff'

    },
    text: {
      primary: '#357a38', // Testo principale in verde scuro
      secondary: '#ff9100', // Testo secondario in oro
      info: '#b0b0b0', // Nuovo colore personalizzato per il testo
      attivita:'#3333ff',
      attivitadata: '#000000',
      attivitavalore: '#000000',
    },
  },
});

export default theme;
